import { createSlice } from "@reduxjs/toolkit";

const CredentialsInitialState = {};

// const CredentialsIntialState = {};
// export const Credentials = createReducer(CredentialsIntialState, {
//   setCredentials: (state, action) => {
//     return { ...action.payload };
//   },
//   deleteCredentials: (state, action) => {
//     return CredentialsIntialState;
//   },
//   addCredentials: (state, action) => {
//     return { ...state, ...action.payload };
//   },
// });


export const Credentials = createSlice({
  name: "Cred",
  initialState: CredentialsInitialState,
  reducers: {
    // setCredentials: (state, action) => {
    //   console.log(state, action)
    //   Object.assign(state, action.payload);
    // },
    
    // addCredentials: (state, action) => {
    //   Object.assign(state, action.payload);
    // },
    
    // deleteCredentials: () => {
    //   return CredentialsInitialState;
    // },
    setCredentials: (state, action) => {
        return { ...action.payload, sub: action.payload.id };
        },
    deleteCredentials: (state, action) => {
          return CredentialsInitialState;
        },
    addCredentials: (state, action) => {
          return { ...state, ...action.payload };
        }
  },
});

export const {
  setCredentials,
  deleteCredentials,
  addCredentials,
} = Credentials.actions;

export default Credentials.reducer;
