import * as yup from 'yup';

export const Schema = yup.object().shape({
  beet: yup.string().required("Beet is required"),
  address: yup.string().required("Address is required"),
  city: yup.string().required("City is required"),
  postalCode: yup
    .number()
    .positive("Postal Code must be positive")
    .integer("Postal Code must be an integer")
    .required("Postal Code is required"),
});


export const OutletSchema = yup.object().shape({
  outletName: yup.string().required("Outlet Name is required"),
  outletType: yup.string().required("Outlet Type is required"),
  ownerName: yup.string().required("Owner Name is required"),
  proprietorName: yup.string().required("Proprietor Name is required"),
  mobile: yup
    .number()
    .positive("Mobile must be positive")
    .integer("Mobile Number must be an integer")
    .required("Mobile Number is required"),
  ownerMobileNo: yup
    .number()
    .positive("Owner Mobile Number must be positive")
    .integer("Owner Mobile Number must be an integer")
    .min(10)
    .required("Owner Mobile Number is required"),
  email: yup.string().required("email is required"),
  gstNumber: yup.string().required("GST Number is required"),
  panNumber: yup.string().required("Pan Number is required"),
});
