import React, { useEffect, useState, useRef } from "react";
import lgAvatar3 from "../../assets/images/lg/avatar3.jpg";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { emailValidator } from "../../helper/emailValidator";
import Swal from "sweetalert2";
import mobileValidator from "../../helper/mobileValidator";
import { passwordValidator } from "../../helper/passwordValidator";
import ModalLoader from "../../screens/UIComponents/ModalLoader";
// import {
//   setAllCategory,
//   setAllCity,
//   setAllDesignation,
//   setAllDivision,
//   setAllRegion,
//   setAllReportingManagers,
//   setAllState,
// } from "../../redux/features/dropdownFieldSlice";

import {
  setAllCategory,
  setAllCity,
  setAllDivision,
  setAllDesignation,
  setAllRegion,
  setAllState,
} from "../../redux/features/dropdownFieldSlice";
import {
  getCategory,
  getCity,
  getCityById,
  getDivision,
  getRegion,
  getRegionById,
  getState,
  getStateById,
} from "../../api/clients/clients-api";
import Select from "react-select";
import {
  getAllDesignation,
  getAllReportingMembers,
} from "../../api/member/member-api";
import { permissionIds } from "../../constants/constants";
import MultiDropdown from "../UI/MultiDropdown";
import SingleDropdown from "../UI/SingleDropdown";
function formatedDate(value) {
  var value = new Date(value);
  const date = value.getDate();
  const year = value.getFullYear();
  const month = value.getMonth() + 1;
  return `${date}-${month}-${year}`;
}

function Our(props) {
  const isMember = props.isMember;
  const MemberPermission = useSelector(
    (state) => state.Permission.memberPermissions
  );
  const Data = props.data;
  // console.log(props.UpdateMember);
  const [isModal, setIsModal] = useState(false);
  const Member = useSelector((state) => state.Member);
  const Cred = useSelector((state) => state.Cred);
  const [firstName, setFirstName] = useState(Data.firstName);
  const [lastName, setLastName] = useState(Data.lastName);
  const [email, setEmail] = useState(Data.email);
  const [mobile, setMobile] = useState(Data.mobile);
  const [joiningDate, setJoiningDate] = useState(Data.joiningDate);
  const [designation, setDesignation] = useState(Data.designation);
  const [employeeId, setEmployeeId] = useState(Data.employeeId);
  const [dob, setDob] = useState(Data.dob);
  const [state, setState] = useState(Data.state);
  const [region, setRegion] = useState(Data.region);
  const [cities, setCities] = useState(Data.cities);
  const [uploadFileKey, setUploadFileKey] = useState(Data.uploadFileKey);
  // console.log("cities", cities)
  // console.log('Data.cities', Data.cities)
  const cityDropRef = useRef(null);
  const [newPassword, setNewPassword] = useState("");
  const [memberPermission, setMemberPermission] = useState([]);
  const [division, setDivision] = useState(Data.divisions);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [allReportingManager, setAllReportingManager] = useState([]);
  const [reportingManager, setReportingManager] = useState(
    Data.reportingManager
  );
  const [myManager, setMyManager] = useState(
    `${Cred.firstName} ${Cred.lastName}`
  );
  const [isPassModal, setIsPassModal] = useState(false);
  const [isPermissionModal, setIsPermissionModal] = useState(false);
  const [loading, setloading] = useState(false);
  const [buttonLoader, setButtonLoader] = useState({
    assignPermission: false,
    updatePermission: false,
    updateMember: false,
    updatePassword: false,
  });
  const DropDownsField = useSelector((state) => state.DropDownsField);
  const DropdownMembers = useSelector((state) => state.Member);
  const Dispatch = useDispatch();
  const setInitialValue = (setStateFunction, initialValue) => {
    setStateFunction(initialValue);
  };
  const checkedValue = props.checkedValue;
  const [isChecked, setIsChecked] = useState(checkedValue);
  const [passText, setPassText] = useState("password");
  useEffect(() => {
    setIsChecked(checkedValue);
  }, [checkedValue]);

  function handleChange() {
    setIsChecked((prev) => (prev === false ? true : false));
  }

  // async function onEndReachMember() {
  //   setButtonLoader({
  //     ...buttonLoader,
  //     ...{ loadMoreMember: true },
  //   });
  //   try {
  //     const resp = await getAllMembers(
  //       Member.paginationData.number + 1,
  //       Cred.token,
  //       Cred.sub
  //     );
  //     Dispatch(
  //       concatMembers({
  //         allMembers: resp.data,
  //         paginationData: resp.paginationData,
  //       })
  //     );
  //   } catch (error) {
  //     Swal.fire({
  //       title: "Something went wrong",
  //       text: "Can't Fetch More Member. Please Try After Some Time.",
  //       icon: "error",
  //       timer: 2000,
  //     });
  //   }
  //   setButtonLoader({
  //     ...buttonLoader,
  //     ...{ loadMoreMember: false },
  //   });
  // }

  async function onPasswordChange() {
    if (newPassword === confirmPassword) {
      const passError = passwordValidator(confirmPassword);
      if (passError) {
        setIsPassModal(false);
        Swal.fire({
          title: "Invalid Password",
          text: passError,
          timer: 2000,
          icon: "warning",
        }).then((e) => setIsPassModal(true));
        return;
      } else {
        setButtonLoader({ ...buttonLoader, ...{ updatePassword: true } });
        await props.UpdatePassword(Data.id, confirmPassword);
        setNewPassword("");
        setConfirmPassword("");
        setButtonLoader({ ...buttonLoader, ...{ updatePassword: false } });
        setIsPassModal(false);
      }
    } else {
      setIsPassModal(false);
      Swal.fire(
        "Invalid Entry",
        "Make Sure both password are same",
        "warning"
      ).then((e) => {
        if (e.isConfirmed) {
          setIsPassModal(true);
        }
      });
    }
  }
  async function onDelete() {
    if (!Data.id) {
      setIsModal(false);
      Swal.fire("Can't Fetch Data", "Can't Fetch Member data");
      return;
    }
    Swal.fire({
      title: "Are You Sure ?",
      text: "You are about to delete an member. if yes please press yes",
      confirmButtonText: "yes",
      denyButtonText: "no",
      showCancelButton: true,
    }).then(async (e) => {
      if (e.isConfirmed) {
        await props.DeleteMember(props.index, props.data.id);
      }
    });
  }

  async function getReportingMember(state, cities, region) {
    // console.log(state, cities, region)
    try {
      const resp = await getAllReportingMembers(
        Cred.token,
        state,
        cities,
        region,
        Cred.sub
      );
      setAllReportingManager(resp);
      // console.log(resp);
    } catch (error) {
      setIsModal(false);
      Swal.fire({
        title: "Something went wrong!",
        text: "Can't Fetch Reporting Manager. Please try After Some Time",
        icon: "error",
      });
    }
  }

  async function onUpdate() {
    const emailError = emailValidator(email);
    const mobileError = mobileValidator(mobile);
    if (emailError) {
      setIsModal(false);

      Swal.fire({
        title: "Invalid Email",
        text: emailError,
        timer: 2000,
        icon: "warning",
      }).then((e) => setIsModal(true));
      return;
    }
    if (mobileError) {
      setIsModal(false);
      Swal.fire({
        title: "Invalid Mobile",
        text: mobileError,
        timer: 2000,
        icon: "warning",
      }).then((e) => setIsModal(true));
      return;
    }

    // console.table(firstName,lastName,email,mobile,joiningDate,employeeId,dob,region,state,cities,designation, Data.id)
    if (
      !firstName ||
      !lastName ||
      !email ||
      !mobile ||
      !joiningDate ||
      !designation ||
      !employeeId ||
      !dob ||
      !region ||
      !state ||
      !cities.length > 0
    ) {
      setIsModal(false);

      Swal.fire({
        title: "Invalid Details",
        text: "Make Sure You Filled Each Details With Correct Value",
        timer: 2000,
        icon: "warning",
      }).then((e) => setIsModal(true));
      return;
    }

    const data = {
      firstName: firstName,
      lastName: lastName,
      designation: designation,
      email: email,
      mobile: mobile,
      joiningDate: joiningDate,
      employeeId: employeeId,
      id: Data.id,
      dob: dob,
      region: region.id,
      state: state.id,
      cities: cities,
      divisions: division.map((item) => {
        return { divisionName: item.divisionName, id: item.id };
      }),
      reportingManager: reportingManager,
      uploadFileKey: uploadFileKey,
    };

    // console.log("data" , data)
    setButtonLoader({ ...buttonLoader, ...{ updateMember: true } });
    await props.UpdateMember(data, props.index);
    setButtonLoader({ ...buttonLoader, ...{ updateMember: false } });
    setIsModal(false);
  }

  async function onAssignPermission() {
    if (!Data.id) {
      setIsModal(false);
      Swal.fire("Can't Fetch Data", "Can't Fetch Member data");
      return;
    }
    setButtonLoader({ ...buttonLoader, ...{ assignPermission: true } });
    const resp = await props.fetchPermissionOfAMember(Data.id);
    setButtonLoader({ ...buttonLoader, ...{ assignPermission: false } });
    setMemberPermission(resp);
    setIsPermissionModal(true);
  }

  async function onUpdatePermission() {
    setButtonLoader({ ...buttonLoader, ...{ updatePermission: true } });
    const payLoad = [];
    for (var i in memberPermission) {
      if (memberPermission[i].assigned) {
        payLoad.push(memberPermission[i].id);
      }
    }
    await props.UpdatePermissionOfAMember(Data.id, payLoad);
    setIsPermissionModal(false);
    setButtonLoader({ ...buttonLoader, ...{ updatePermission: false } });
  }

  async function getAllDivision() {
    if (DropDownsField.allDivision.length <= 0) {
      const divisionArray = await getDivision(Cred.token);
      Dispatch(setAllDivision(divisionArray));
      if (division instanceof Array) {
        const nDivison = division.map((item) => {
          return { value: item.id };
        });
      } else {
        setDivision([]);
      }
    }
  }

  async function getAllRegion() {
    if (DropDownsField.allRegion.length <= 0) {
      const regionArray = await getRegion(Cred.token);
      Dispatch(setAllRegion(regionArray));
    }
  }

  async function getAllState(regionId = Data.region) {
    Dispatch(setAllState([]));
    const stateArray = await getState(Cred.token, regionId);
    Dispatch(setAllState(stateArray));
  }

  async function getAllCities(stateId = Data.state) {
    Dispatch(setAllCity([]));
    const citiesArray = await getCity(Cred.token, stateId);
    Dispatch(setAllCity(citiesArray));
  }

  async function getDropDownsValue() {
    Dispatch(setAllCity([]));
    Dispatch(setAllState([]));
    await Promise.all([getAllDivision(), getAllRegion(), getAllState(), getAllCities()]);
    await getCall();
  }

  // console.log(Data)

  async function getCall() {
    if (!DropDownsField.allDesignation.length > 0) {
      const desg = await getAllDesignation(Cred.token);
      Dispatch(setAllDesignation(desg.data));
    }

    let region = null;
    let states = null;

    const fetchedRegion = Data.region
      ? 
      await getRegionById(Cred.token, Data.region)
      : null;
      
    const fetchedState = Data.state
      ? await getStateById(Cred.token, Data.state)
      : null;

    if(fetchedRegion){
      region = fetchedRegion;
    }

    if(fetchedState){
      states = fetchedState;
    }

    setRegion(region);
    setCities(Data.cities);
    setState(states);

    console.log(cities)
  }

  return (
    <>
      <div className="card teacher-card">
        <div className="card-body  d-flex">
          <div className="profile-av pe-xl-4 pe-md-2 pe-sm-4 pe-4 text-center w220">
            <img
              // src={Data.uploadFileKey?`https://prismsfa-bucket.s3.ap-south-1.amazonaws.com/${Data.uploadFileKey}`:lgAvatar3}
              src={lgAvatar3}
              alt=""
              className="avatar xl rounded-circle img-thumbnail shadow-sm"
            />
            <div className="about-info d-flex align-items-center mt-1 justify-content-center flex-column">
              <h6 className="mb-0 fw-bold d-block fs-6 mt-2">
                {Data.employeeId}
              </h6>
            </div>
            <div
              className="btn-group mt-2"
              role="group"
              aria-label="Basic outlined example"
            >
              {(MemberPermission?.some(
                (item) => item == permissionIds.manager
              ) ||
                MemberPermission?.some(
                  (item) => item == permissionIds.editManager
                )) && (
                <button
                  type="button"
                  onClick={async () => {
                    try {
                      if (!Data.id) {
                        setIsModal(false);
                        Swal.fire(
                          "Can't Fetch Data",
                          "Can't Fetch Member data"
                        );
                        return;
                      }
                      setIsModal(true);
                      setloading(false);
                      await getDropDownsValue();
                    } catch (error) {
                      console.log(error);
                      setIsModal(false);
                      Swal.fire(
                        "Something went wrong",
                        "Can't Fetch Necessary data"
                      );
                    }
                  }}
                  className="btn btn-outline-secondary"
                >
                  <i className="icofont-edit text-success"></i>
                </button>
              )}
              {(MemberPermission?.some(
                (item) => item == permissionIds.manager
              ) ||
                MemberPermission?.some(
                  (item) => item == permissionIds.deleteManager
                )) && (
                <button
                  type="button"
                  onClick={onDelete}
                  className="btn btn-outline-secondary"
                >
                  <i className="icofont-ui-delete text-danger"></i>
                </button>
              )}{" "}
            </div>
          </div>

          <div className="teacher-info border-start ps-xl-4 ps-md-3 ps-sm-4 ps-4 w-100">
            <div className="d-flex flex-row flex-wrap justify-content-between">
              <span className="light-info-bg py-1 px-2 rounded-1 d-inline-block fw-bold small-11 mb-0 mt-1">
                {Data.firstName + " " + Data.lastName}
              </span>

              {MemberPermission.some(
                (item) => item.code == permissionIds.viewMember && item.assigned
              ) &&
                MemberPermission.some(
                  (item) =>
                    item.code == permissionIds.editMember && item.assigned
                ) && (
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      style={{
                        border: "1px",
                        borderStyle: "solid",
                        borderColor: "#333",
                      }}
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleChange}
                    />
                  </div>
                )}
            </div>
            <div className="video-setting-icon mt-3 pt-3 border-top">
              <p>Joining Date : {Data.joiningDate}</p>

              <p>Mobile : {Data.mobile}</p>

              <p>Email : {Data.email}</p>
            </div>

            {MemberPermission.some(
              (item) => item.code == permissionIds.viewMember && item.assigned
            ) &&
              MemberPermission.some(
                (item) =>
                  item.code == permissionIds.assignPermission && item.assigned
              ) && (
                <Button
                  variant="primary"
                  className="btn btn-dark btn-sm mt-1 me-2"
                  onClick={() => onAssignPermission()}
                >
                  {buttonLoader.assignPermission ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-1"
                    />
                  ) : (
                    <i className="icofont-plus-circle me-2 fs-6  "></i>
                  )}
                  Assign Permission
                </Button>
              )}
          </div>
        </div>
      </div>
      <Modal
        centered
        show={isModal}
        size="lg"
        onHide={() => {
          setIsModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Edit Employee</Modal.Title>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "row",
              flex: 1,
            }}
          >
            <Button
              onClick={() => {
                setIsModal(false);
                setIsPassModal(true);
              }}
              variant="primary"
            >
              Update Password
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            <div className="mb-3">
              <label htmlFor="firstNameInput" className="form-label">
                Employee First Name*
              </label>
              <input
                type="text"
                className="form-control"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                id="firstNameInput"
                placeholder="First Name"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="lastNameInput" className="form-label">
                Employee Last Name*
              </label>
              <input
                type="text"
                className="form-control"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                id="lastNameInput"
                placeholder="Last Name"
              />
            </div>

            <div className="mb-3">
              <label htmlFor="joiningDateInput" className="form-label">
                Joining Date*
              </label>
              <input
                type="date"
                className="form-control"
                value={joiningDate}
                onChange={(e) => setJoiningDate(e.target.value)}
                id="joiningDateInput"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="employeeIdInput" className="form-label">
                Employee Id*
              </label>
              <input
                type="text"
                className="form-control"
                value={employeeId}
                onChange={(e) => setEmployeeId(e.target.value)}
                id="employeeIdInput"
                placeholder="ID"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="dobInput" className="form-label">
                Dob Date*
              </label>
              <input
                type="date"
                className="form-control"
                value={dob}
                onChange={(e) => setDob(e.target.value)}
                id="dobInput"
              />
            </div>
            <div className="deadline-form">
              <form>
                <div className="row g-3 mb-3">
                  <div className="col-lg-6">
                    <label htmlFor="emailInput" className="form-label">
                      Email*
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="emailInput"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email Id"
                    />
                  </div>
                  <div className="col-lg-6">
                    <label htmlFor="mobileInput" className="form-label">
                      Mobile Number*
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="mobileInput"
                      value={mobile}
                      maxLength={10}
                      onChange={(e) => setMobile(e.target.value)}
                      placeholder="Mobile Number"
                    />
                  </div>
                </div>
                <div className="row g-3 mb-3">
                  <div className="col-sm-6">
                    <label className="form-label">Designation*</label>
                    <select
                      className="form-select"
                      value={designation}
                      onChange={(e) => {
                        const selectedValue = e.target.value;
                        setDesignation(selectedValue);
                      }}
                    >
                      {" "}
                      <option value="">Select a Designation</option>
                      {DropDownsField.allDesignation.map((value, i) => {
                        return (
                          <option value={value.id} key={value.id}>
                            {value.designationName} ({value.id})
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-lg-6">
                    <label
                      className="form-label"
                      htmlFor="exampleFormControlInput478"
                    >
                      Region*
                    </label>
                    <select
                      className="form-select"
                      id="exampleFormControlInput478"
                      value={region.id}
                      onChange={async (e) => {
                        try {
                          setRegion("");
                          const selectedValue = e.target.value;
                          setRegion(selectedValue);
                          console.log("selected region", selectedValue)
                          await getAllState(selectedValue);
                        } catch (error) {
                          Swal.fire(
                            "Something went wrong",
                            "Please Try After Some Time",
                            "error"
                          );
                        }
                      }}
                    >
                      <option value="">Select a region</option>
                      {DropDownsField.allRegion.map((value, i) => {
                        return (
                          <option value={value.id} key={value.id}>
                            {value.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-lg-6">
                    <label
                      className="form-label"
                      htmlFor="exampleFormControlInput578"
                    >
                      State*
                    </label>
                    <select
                      className="form-select"
                      id="exampleFormControlInput578"
                      value={state.id}
                      onChange={async (e) => {
                        try {
                          setCities([]);
                          const selectedValue = e.target.value;
                          setState(selectedValue);
                          console.log("selected state", selectedValue)
                          await getAllCities(selectedValue);
                        } catch (error) {
                          Swal.fire(
                            "Something went wrong",
                            "Please Try After Some Time",
                            "error"
                          );
                        }
                      }}
                    >
                      <option value="">Select a State</option>
                      {DropDownsField.allState.map((value, i) => {
                        return (
                          <option value={value.id} key={value.id}>
                            {value.stateName}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <MultiDropdown
                    isPagination={false}
                    title="Cities"
                    data={cities}
                    dropdownRef={cityDropRef}
                    dropdownData={
                      DropDownsField.allCity.length > 0
                        ? DropDownsField.allCity
                        : []
                    }
                    handleChange={(data) => {
                      setCities(data);
                    }}
                  />

                  <div className="col-sm-6">
                    <label
                      htmlFor="exampleFormControlInput878"
                      className="form-label"
                    >
                      Reporting Manager
                    </label>
                    {!state || !region || !cities > 0 ? (
                      <div
                        className="form-control"
                        onClick={() => {
                          setIsModal(false);
                          Swal.fire(
                            "Please Select All Available Dropdowns",
                            "Like States, Cities, Division etc"
                          ).then((e) => setIsModal(true));
                        }}
                      >
                        {" "}
                        <label className="form-label">Reporting Manager</label>
                      </div>
                    ) : (
                      <select
                        id="exampleFormControlInput878"
                        className="form-select"
                        // value={`${Cred.firstName} ${Cred.lastName}`}
                        value={reportingManager}
                        onChange={(e) => {
                          // console.log(reportingManager)
                          const selectedValue = e.target.value;
                          setReportingManager(selectedValue);
                          // console.log(reportingManager);
                        }}
                      >
                        <option value={Cred.sub}>
                          {Cred.firstName} {Cred.lastName}
                        </option>

                        {DropdownMembers.allMembers.map((value, i) => {
                          return (
                            <option value={value.id} key={value.id}>
                              {value.firstName} {value.lastName}
                            </option>
                          );
                        })}
                      </select>
                    )}
                  </div>

                  <div className="col-sm-6">
                    <label
                      htmlFor="exampleFormControlInput2778"
                      className="form-label"
                    >
                      Joining Date*
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      value={joiningDate}
                      onChange={(e) => setJoiningDate(e.target.value)}
                      id="exampleFormControlInput2778"
                    />
                  </div>
                 
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                setInitialValue(setFirstName, Data.firstName || "");
                setInitialValue(setLastName, Data.lastName || "");
                setInitialValue(setEmail, Data.email || "");
                setInitialValue(setMobile, Data.mobile || "");
                setInitialValue(setJoiningDate, Data.joiningDate || "");
                setInitialValue(setDesignation, Data.designation || "");
                setInitialValue(setEmployeeId, Data.employeeId || "");
                setInitialValue(setDob, Data.dob || "");
                setInitialValue(setState, Data.state || "");
                setInitialValue(setRegion, Data.region || "");
                setInitialValue(setCities, Data.cities || "");
                setInitialValue(setNewPassword, "");
                setInitialValue(setMemberPermission, []);
                setInitialValue(setDivision, Data.division || "");
                setInitialValue(setUploadFileKey, Data.uploadFileKey || "");
                setInitialValue(setConfirmPassword, "");
                setIsModal(false);
              }}
            >
              Cancel
            </button>
            <Button
              variant="primary"
              onClick={async () => {
                try {
                  onUpdate();
                } catch (error) {}
              }}
            >
              {buttonLoader.updateMember && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-1"
                />
              )}
              Update
            </Button>
          </>
        </Modal.Footer>
      </Modal>
      <Modal
        show={isPassModal}
        centered
        size="lg"
        onHide={() => {
          setIsPassModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Update Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <label htmlFor="firstNameInput" className="form-label">
              New Password
            </label>
            <div className="d-flex justify-content-between align-items-center">
              <input
                type={passText}
                className="form-control"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                id="firstNameInput"
                placeholder="New Password"
              />
              <div
                className="fs-4"
                onClick={() =>
                  setPassText((prev) =>
                    prev === "password" ? "text" : "password"
                  )
                }
              >
                {passText === "password" ? (
                  <i class="icofont-eye-blocked"></i>
                ) : (
                  <i class="icofont-eye"></i>
                )}
              </div>
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="lastNameInput" className="form-label">
              Confirm Password
            </label>
            <input
              type={passText}
              className="form-control"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              id="lastNameInput"
              placeholder="Confirm Password"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => onPasswordChange()}>
            {buttonLoader.updatePassword && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-1"
              />
            )}
            Change Password
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={isPermissionModal}
        centered
        size="lg"
        onHide={() => {
          setIsPermissionModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Assign Permission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive">
            <p style={{ fontSize: 20, fontWeight: "normal" }}>
              Member Permission
            </p>
            <table className="custom-table">
              <thead>
                <tr>
                  <th>Permission Name</th>
                  <th>Assign</th>
                </tr>
              </thead>
              <tbody>
                {memberPermission.map((item, index) => (
                  <>
                    {item.permissionGroupId == 1 && (
                      <tr
                        key={item.id}
                        className={index % 2 === 0 ? "darker-row" : ""}
                      >
                        <td className="fw-bold">{item.name}</td>
                        <td>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            value=""
                            id={`flexCheckDefault2+${item.code}`}
                            checked={item.assigned}
                            onChange={() => {
                              const newData = { ...item };
                              newData["assigned"] = !item.assigned;
                              const newMemberPermission = [...memberPermission];
                              newMemberPermission[index] = newData;
                              setMemberPermission(newMemberPermission);
                            }}
                          />
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              onUpdatePermission();
            }}
            className="btn btn-primary"
          >
            {buttonLoader.updatePermission && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-1"
              />
            )}
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <ModalLoader show={loading} message={"Fetching Details"} />
    </>
  );
}

export default Our;
