// MainIndex.js

import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Header from "../components/common/Header";
import Expenses from "./Accounts/Expenses";
import Invoices from "./Accounts/Invoices";
import Payments from "./Accounts/Payments";
import HrDashboard from "./Dashboard/HrDashboard";
import ProjectDashboard from "./Dashboard/ProjectDashboard";
import Attendance from "./Employee/Attendance";
import Departments from "./Employee/Departments";
import EmployeeProfile from "./Employee/EmployeeProfile";
import Holidays from "./Employee/Holidays";
import LeaveRequest from "./Employee/LeaveRequest";
import Members from "./Employee/Members";
import Clients from "./Our Clients/Clients";
import Salaryslip from "./Payroll/Salaryslip";
import Leaders from "./Projects/Leaders";
import Projects from "./Projects/Projects";
import Tasks from "./Projects/Tasks";
import Timesheet from "./Projects/Timesheet";
import TicketsDetail from "./Tickets/TicketsDetail";
import TicketsView from "./Tickets/TicketsView";
import Alerts from "./UIComponents/Alerts";
import Calendar from "./App/Calendar";
import ChatApp from "./App/ChatApp";
import ApexCharts from "./OtherPages/ApexCharts";
import FormsExample from "./OtherPages/FormsExample";
import TablesExample from "./OtherPages/TablesExample";
import ReviewsPage from "./OtherPages/ReviewsPage";
import Icons from "./OtherPages/Icons";
import Widgets from "./OtherPages/Widgets";
import Badges from "./UIComponents/Badges";
import Page404 from "../components/Auth/Page404";
import Breadcrumb from "./UIComponents/Breadcrumb";
import Buttons from "./UIComponents/Buttons";
import Cards from "./UIComponents/Cards";
import Carousel from "./UIComponents/Carousel";
import Collapse from "./UIComponents/Collapse";
import Dropdowns from "./UIComponents/Dropdowns";
import ListGroup from "./UIComponents/ListGroup";
import ModalUI from "./UIComponents/ModalUI";
import NavsUI from "./UIComponents/NavsUI";
import NavbarUI from "./UIComponents/NavbarUI";
import PaginationUI from "./UIComponents/PaginationUI";
import PopoversUI from "./UIComponents/PopoversUI";
import ProgressUI from "./UIComponents/ProgressUI";
import Scrollspy from "./UIComponents/Scrollspy";
import SpinnersUI from "./UIComponents/SpinnersUI";
import ToastsUI from "./UIComponents/ToastsUI";
import StaterPage from "./Stater/StaterPage";
import PageHeader1 from "../components/common/PageHeader1";
import Documentation from "./Documentation/Documentation";
import Changelog from "./Changelog/Changelog";
import Help from "./Dashboard/Help";
import { constants } from "../constants/constants";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import {
  getMemberDetail,
  getPermissionOfAMember,
} from "../api/member/member-api";

import {
  deleteCredentials,
  addCredentials,
} from "../redux/features/credentialSlice";

import { setMemberPermissions } from "../redux/features/permissionSlice";

import Schedules from "./Employee/Schedules";
import { useState } from "react";
import Loading from "../components/UI/Loading";
import Profile from "./Employee/Profile";
import Products from "./Products/Master/ProductMaster";
import PrimarySales from "./Sales/PrimarySales/PrimarySales";
import SecondarySales from "./Sales/SecondarySales/SecondarySales";
import Status from "./Status/Status";
import Salary from "./Accounts/Salary";
import { addMembers } from "../redux/features/memberSlice";
import DCR from "./DCR/DCR";
import Beet from "./Beet/Beet";
import Tenant from "./Tenant/Tenant";
import ProductMaster from "./Products/Master/ProductMaster";
import ProductPrice from "./Products/Price/ProductPrice";

function MainIndex(props) {
  const Dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const Cred = useSelector((state) => state.Cred);

  async function get() {
    setLoading(true);
    try {
      const decode = await jwtDecode(Cred.token);
      // console.log("Decode in main", decode)
      const expTime = new Date(decode.exp * 1000);
      const currentTime = new Date();
      if (currentTime > expTime) {
        window.localStorage.removeItem(constants.token_store);
        Dispatch(deleteCredentials());
      } else {
        const data = await getMemberDetail(decode.id, Cred.token);
     
        const {permission, status} = await getPermissionOfAMember(decode.id, Cred.token);
        Dispatch(
          setMemberPermissions(permission)
          // setMemberPermissions(
          //   permission.map((item) => {
          //     if (item.permissionGroupId == 1) {
          //       return item;
          //     }
          //   })
          // )
        );
        Dispatch(addCredentials(data));

        // your error
      }
    } catch (error) {
      // console.log("Error:: ", error)
      window.localStorage.removeItem(constants.token_store);
      Dispatch(deleteCredentials());
    }
    setLoading(false);
  }

  useEffect(() => {
    get();
    return () => {};
  }, []);

  return (
    <div className="main px-lg-4 px-md-4">
      {loading ? (
        <Loading animation={"border"} />
      ) : (
        <>
          <Header />
          <Routes>
            <Route path="/" element={<Members />} />
            <Route path="expenses" element={<Expenses />} />
            <Route path="attendance" element={<Attendance />} />
            <Route path="clients" element={<Clients />} />
            <Route path="calender" element={<Calendar />} />
            <Route path="members-scheduler" element={<Schedules />} />
            <Route path="member-profile" element={<Profile />} />
            <Route path="leave-request" element={<LeaveRequest />} />
            <Route path="status-report" element={<Status />} />
            <Route path="call-report" element={<DCR />} />
            <Route path="productprice" element={<ProductPrice />} />
            <Route path="productmaster" element={<ProductMaster />} />
            <Route path="primarySales" element={<PrimarySales />} />
            <Route path="beets" element={<Beet />} />
            <Route path="tenant" element={<Tenant />} />
            <Route path="secondarySales" element={<SecondarySales />} />
            <Route path="holidays" element={<Holidays />} />
            <Route path="*" element={<Page404 />} />
          </Routes>
        </>
      )}
    </div>
  );
}

export default MainIndex;
