import axios from 'axios';
import {API_URL} from '../../constants/api-url';

export async function addBeet(data, token) {
  const url = API_URL.backend_url + 'beet';
  const resp = await axios({
    url: url,
    method: 'post',
    headers: {
      'Content-type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    data,
  });

  return resp.data;
}

export async function getAllBeet(token, page, size) {
  const url =
    API_URL.backend_url + 'beetAll?' + 'page=' + page + '&size=' + size;
  const resp = await axios({
    url: url,
    method: 'get',
    headers: {
      'Content-type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return resp.data;
}

export async function addOutLet(token, payload) {
  const url = API_URL.backend_url + 'outletMapWithBeet';
  const resp = await axios({
    url: url,
    method: 'post',
    headers: {
      'Content-type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    data: payload,
  });
  return resp.data;
}
