import { createSlice } from "@reduxjs/toolkit";

const initialMembers = { allMembers: [], paginationData: { number: 0 } };

export const Members = createSlice({
  name: "Member",
  initialState: initialMembers,
  reducers: {
    setMembers: (state, action) => {
      state.paginationData = action.payload.paginationData;
      state.allMembers = [...action.payload.allMembers];
    },
    addMembers: (state, action) => {
      state.allMembers.push(action.payload);
    },
    deleteMembers: (state, action) => {
      state.allMembers = state.allMembers.filter(
        (member) => member.id !== action.payload
      );
    },
    updateMembers: (state, action) => {
      state.allMembers = state.allMembers.map((member) =>
        member.id === action.payload.id ? action.payload : member
      );
    },
    deleteAllMembers: (state, action) => {
      return initialMembers;
    },
    concatMembers: (state, action) => {
      state.allMembers = [...state.allMembers, ...action.payload.allMembers];
      state.paginationData = {
        ...state.paginationData,
        ...action.payload.paginationData,
      };
    },
  },
});

export const {
  setMembers,
  addMembers,
  deleteAllMembers,
  updateMembers,
  deleteMembers,
  concatMembers,
} = Members.actions;

export default Members.reducer;
