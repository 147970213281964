import { combineReducers, configureStore } from "@reduxjs/toolkit";

import  Credentials  from "./features/credentialSlice";
import  DropDownsField  from "./features/dropdownFieldSlice";
import  Members  from "./features/memberSlice";
import  Clients  from "./features/clientSlice";
import  DropDownMembers  from "./features/dropdownMemberSlice";
import  DropDownsClients  from "./features/dropdownClientSlice";
import  Schedules  from "./features/schedulesSlice";
import  Leaves from "./features/leavesSlice";
import  LeaveRequests  from "./features/leaveRequestSlice";
import  Products  from "./features/productsSlice";
import  Status  from "./features/statusSlice";
import  Holiday  from "./features/holidaySlice";
import  PrimarySales  from "./features/primarySalesSlice"; 
import  Expenses  from "./features/expenseSlice"
import  SecondarySales  from "./features/secondarySalesSlice";
import  Salary from "./features/salarySlice"
import  PermissionSlice from "./features/permissionSlice"
import  Dcr  from "./features/dcrSlice";
import  BeetSlice from "./features/beetSlice";
import Tenant from "./features/tenantSlice";
import attendanceSlice from "./features/attendanceSlice";

const rootReducer = combineReducers({
  Cred: Credentials,
  DropDownsField: DropDownsField,
  Member: Members,
  Client: Clients,
  DropDownMembers: DropDownMembers,
  DropDownsClients: DropDownsClients,
  Schedules: Schedules,
  Expenses: Expenses,
  Leaves: Leaves,
  LeaveRequests: LeaveRequests,
  Products: Products,
  Status: Status,
  Dcr: Dcr,
  PrimarySales: PrimarySales,
  SecondarySales: SecondarySales,
  Holiday: Holiday,
  Salary : Salary,
  Permission:PermissionSlice.reducer,
  Beets: BeetSlice,
  Tenants: Tenant,
  Attendance : attendanceSlice
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;


