import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { OutletSchema } from "./Schema";
import { addBeet, addOutLet } from "../../../api/beet/beet-api";
import { addOutletToBeet, addSingleBeet } from "../../../redux/features/beetSlice";
import Swal from "sweetalert2";

function AddUpdateOutlet({ editData, handleIsModal, beetId, outletData, handleOutletViewModal }) {
  const [buttonLoader, setButtonLoader] = useState(false);
  const dispatch = useDispatch();
  const Cred = useSelector((state) => state.Cred)

  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(OutletSchema) });

  console.log('outlets',outletData);
  console.log('beetId',beetId);

  const handleDataChange = async (values) => {
    setButtonLoader(true);
    const payload = {
      ...values,
      remark: "",
      beet: {
        id: beetId,
      },
    };
    console.log("Payload:", payload);
    if (editData) {
      try {
        // Update logic here
      } catch (error) {
        Swal.fire("Error", "Unable to Update Outlet Details");
      }
    } else {
      try {
        const resp = await addOutLet(Cred.token, payload);
        if (resp) {
          dispatch(addOutletToBeet({
            data: payload,
            beetId: beetId // Use the dynamic beetId
          }));
          handleOutletViewModal()
          handleIsModal();
          Swal.fire("Success", "Outlet added successfully");
        }
      } catch (error) {
        handleIsModal();
        console.log("Error Adding Outlet", error);
        Swal.fire("Error", "Unable to Add Outlet!");
      }
    }
    setButtonLoader(false);
    reset();
  };
  

  return (
    <form onSubmit={handleSubmit(handleDataChange)}>
      <div className="row g-3">
        <div className="col-lg-6">
          <label htmlFor="outletName" className="form-label">
            Outlet Name
          </label>
          <input
            type="text"
            className="form-control"
            id="outletName"
            placeholder="Outlet Name"
            {...register("outletName")}
          />
          <p className="text-danger">{errors.outletName?.message}</p>
        </div>
        <div className="col-lg-6">
          <label htmlFor="outletType" className="form-label">
            Outlet Type
          </label>
          <input
            type="text"
            className="form-control"
            id="outletType"
            placeholder="Outlet Type"
            {...register("outletType")}
          />
          <p className="text-danger">{errors.outletType?.message}</p>
        </div>
      </div>
      <div className="mb-3">
        <label htmlFor="mobile" className="form-label">
          Mobile Number
        </label>
        <input
          type="text"
          className="form-control"
          id="mobile"
          placeholder="Mobile Number"
          {...register("mobile")}
        />
        <p className="text-danger">{errors.mobile?.message}</p>
      </div>
      <div className="mb-3">
        <label htmlFor="proprietorName" className="form-label">
          Proprietor Name
        </label>
        <input
          type="text"
          className="form-control"
          id="proprietorName"
          placeholder="Proprietor Name"
          {...register("proprietorName")}
        />
        <p className="text-danger">{errors.proprietorName?.message}</p>
      </div>
      <div className="row g-3">
        <div className="col-lg-6">
          <label htmlFor="ownerName" className="form-label">
            Owner Name
          </label>
          <input
            type="text"
            className="form-control"
            id="ownerName"
            placeholder="Owner Name"
            {...register("ownerName")}
          />
          <p className="text-danger">{errors.ownerName?.message}</p>
        </div>
        <div className="col-lg-6">
          <label htmlFor="ownerMobileNo" className="form-label">
            Owner Mobile Number
          </label>
          <input
            type="text"
            className="form-control"
            id="ownerMobileNo"
            placeholder="Owner Mobile Number"
            {...register("ownerMobileNo")}
          />
          <p className="text-danger">{errors.ownerMobileNo?.message}</p>
        </div>
      </div>
      <div className="mb-3">
        <label htmlFor="email" className="form-label">
          Email
        </label>
        <input
          type="email"
          className="form-control"
          id="email"
          placeholder="Email"
          {...register("email")}
        />
        <p className="text-danger">{errors.email?.message}</p>
      </div>
      <div className="row g-3">
        <div className="col-lg-6">
          <label htmlFor="gstNumber" className="form-label">
            GST Number
          </label>
          <input
            type="text"
            className="form-control"
            id="gstNumber"
            placeholder="GST Number"
            {...register("gstNumber")}
          />
          <p className="text-danger">{errors.gstNumber?.message}</p>
        </div>
        <div className="col-lg-6">
          <label htmlFor="panNumber" className="form-label">
            Pan Number
          </label>
          <input
            type="text"
            className="form-control"
            id="panNumber"
            placeholder="Pan Number"
            {...register("panNumber")}
          />
          <p className="text-danger">{errors.panNumber?.message}</p>
        </div>
      </div>
      <div className="w-100 d-flex gap-2 justify-content-end mt-4 mb-3">
        <button className="btn btn-secondary" onClick={handleIsModal}>
          Done
        </button>
        <button className="btn btn-primary" type="submit">
          {buttonLoader && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="me-1"
            />
          )}
          {editData ? "Update" : "Add"}
        </button>
      </div>
    </form>
  );
}

export default AddUpdateOutlet;
