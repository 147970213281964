import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Schema } from "./Schema";
import { addBeet } from "../../../api/beet/beet-api";
import { addSingleBeet } from "../../../redux/features/beetSlice";
import Swal from "sweetalert2";

function AddUpdateModal({ editData, handleIsModal }) {
  const [buttonLoader, setButtonLoader] = useState(false);
  const CredDetails = useSelector((state) => state.Cred);
  const dispatch = useDispatch();

  function getOptions() {
    if (CredDetails?.cities.length > 0 && Array.isArray(CredDetails.cities)) {
      return CredDetails.cities.map((item) => (
        <option key={item.cityName} value={item.id}>
          {item.cityName}
        </option>
      ));
    }
    return <option value="">No cities available</option>;
  }

  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(Schema) });

  const handleDataChange = async (values) => {
    setButtonLoader(true);

    const selectedCity = CredDetails.cities.filter((item) =>
      item.id === values.city ? { id: item.id, cityName: item.cityName } : {}
    );
    const payload = {
      ...values,
      remark: "",
      members: {
        id: CredDetails.id,
        firstName: CredDetails.firstName,
        lastName: CredDetails.lastName,
      },
      outlets: [],
      city: {
        id: selectedCity[0].id,
        cityName: selectedCity[0].cityName,
      },
    };

    try {
      if (editData) {
        // data update logic
      } else {
        // data add logic

        console.log(payload);
        const resp = await addBeet(payload, CredDetails?.token);
        if (resp.beetId) {
          dispatch(
            addSingleBeet({
              ...payload, 
              beetId: resp.beetId
            })
          );
          Swal.fire("Success", "Beet added successfully", "success");
        }
      }
      reset(); // Reset the form on success
      handleIsModal(); // Close the modal on success
    } catch (error) {
      // Handle error
      handleIsModal();
      console.error("Error adding beet:", error);
      Swal.fire(
        "Error",
        "An error occurred while adding the beet. Please try again.",
        "error"
      );
    } finally {
      setButtonLoader(false); // Stop the loader in both success and error cases
    }
  };

  return (
    <form onSubmit={handleSubmit(handleDataChange)}>
      <div className="mb-3">
        <label htmlFor="beetName" className="form-label">
          Beet Name *
        </label>
        <input
          type="text"
          className="form-control"
          id="beetName"
          placeholder="Beet Name"
          {...register("beet")}
        />
        <p className="text-danger">{errors.beet?.message}</p>
      </div>
      <div className="row g-3">
        <div className="col-lg-8">
          <label htmlFor="address" className="form-label">
            Address *
          </label>
          <input
            type="text"
            className="form-control"
            id="address"
            placeholder="Beet Address"
            {...register("address")}
          />
          <p className="text-danger">{errors.address?.message}</p>
        </div>
        <div className="col-lg-4">
          <label htmlFor="postalCode" className="form-label">
            Postal Code *
          </label>
          <input
            type="text"
            className="form-control"
            id="postalCode"
            placeholder="Postal Code"
            {...register("postalCode")}
          />
          <p className="text-danger">{errors.postalCode?.message}</p>
        </div>
      </div>
      <div className="mb-3">
        <label htmlFor="city" className="form-label">
          City *
        </label>
        <select className="form-control" id="city" {...register("city")}>
          <option value="">Select a city</option>
          {getOptions()}
        </select>
        <p className="text-danger">{errors.city?.message}</p>
      </div>
      <div className="w-100 d-flex gap-2 justify-content-end mt-4 mb-3">
        <button className="btn btn-secondary" onClick={handleIsModal}>
          Done
        </button>
        <button className="btn btn-primary" type="submit">
          {buttonLoader && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="me-1"
            />
          )}
          {editData ? "Update" : "Add"}
        </button>
      </div>
    </form>
  );
}

export default AddUpdateModal;