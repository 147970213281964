import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner, Toast } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import PrimarySalesModal from "./form/Modal";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import Loading from "../../../components/UI/Loading";
import PageHeader from "../../../components/common/PageHeader";
import ModalLoader from "../../UIComponents/ModalLoader";
import { usePrimarySalesHook } from "../../../hooks/primarySalesHook";
import { customStyles } from "../../../constants/customStyles";
import { useProductHook } from "../../../hooks/productsHook";
import Form from "react-bootstrap/Form";
function PrimarySales() {
  const [isModal, setIsModal] = useState(false);
  const [isModalDelete, setIsModalDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  // data store variable
  const [editData, setEditData] = useState(null);
  const [deleteData, setDeleteData] = useState(null);
  const [fetchMessage, setFetchMessage] = useState("");

  // hooks
  const { addPrimarySales, updatePrimarySales, deletePrimarySales } =
    usePrimarySalesHook();
  const { getAllProduct } = useProductHook(0, 500);

  // redux store
  const { allPrimarySales, paginationData } = useSelector(
    (state) => state.PrimarySales
  );
  const { content } = useSelector((state) => state.Products);

  const [modalLoader, setModalLoader] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [buttonLoader, setButtonLoader] = useState({
    getDropDowns: false,
    addProduct: false,
  });

  // modal change function
  function handleIsModalDelete() {
    setIsModalDelete(!isModalDelete);
  }
  function handleIsModal() {
    setIsModal(!isModal);
  }

  const handlePageChange = async (NextPage) => {
    if (
      // Holiday.paginationData.totalPages - 1 == Holiday.paginationData.number ||
      // Holiday.paginationData.number > Holiday.paginationData.totalPages
      console.log(NextPage)
    ) {
      return;
    }

    try {
      // const resp = await getAllHolidays(
      //   Cred.token,
      //   Holiday.paginationData.number + 1
      // );
      // Dispatch(concatHoliday({
      //     allHoliday: resp.data,
      //     paginationData: resp.paginationData,
      //   }));
    } catch (error) {
      Swal.fire("Something Went Wrong", "Can't Fetch More Data");
    }
  };

  var columnsT = "";
  columnsT = [
    {
      name: "PRODUCT NAME",
      selector: (row) => (
        <span className={row.txtClass}>
          {row.productId
            ? content.find((item) => item.productId === row.productId)?.name ||
              "NA"
            : "NA"}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Quantity",
      selector: (row) => (
        <span className={row.txtClass}>
          {row.quantity ? row.quantity : "NA"}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Updated At",
      selector: (row) => (
        <span className={row.txtClass}>
          {row.updatedAt ? row.updatedAt : "-"}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Sale Level",
      selector: (row) => (
        <span className={row.txtClass}>
          {row.salesLevel ? row.salesLevel : "NA"}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Status",
      selector: () => {},
      sortable: true,
      cell: (row) => (
        <div
          className="btn-group"
          role="group"
          aria-label="Basic outlined example"
        >
          {/* <button
            type="button"
            onClick={() => {
              setDeleteData(row);
              handleIsModalDelete();
            }}
            className="btn btn-outline-secondary deleterow"
          >
            <i className="icofont-ui-delete text-danger"></i>
          </button> */}

          <Form>
            <Form.Check // prettier-ignore
              type="switch"
              id="custom-switch"
              label=""
            />
          </Form>
        </div>
      ),
    },
    {
      name: "ACTION",
      selector: () => {},
      sortable: true,
      cell: (row) => (
        <div
          className="btn-group"
          role="group"
          aria-label="Basic outlined example"
        >
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={() => {
              setEditData(row);
              handleIsModal();
            }}
          >
            <i className="icofont-edit text-success"></i>
          </button>
          {/* <button
            type="button"
            onClick={() => {
              setDeleteData(row);
              handleIsModalDelete();
            }}
            className="btn btn-outline-secondary deleterow"
          >
            <i className="icofont-ui-delete text-danger"></i>
          </button> */}
        </div>
      ),
    },
  ];

  const handleDeleteProduct = async (id = deleteData?.id) => {
    try {
      handleIsModalDelete();
      // dispatch(deleteProducts(data.name));
      Swal.fire(
        "Product Deleted",
        "This Product is deleted successfully",
        "success"
      );
    } catch (error) {
      console.log("Error:: ", error);
      handleIsModalDelete();
      Swal.fire("Unable to delete this product", "error");
    }
  };

  return (
    <>
      {loading ? (
        <Loading animation={"border"} color={"black"} />
      ) : (
        <div className="container-xxl">
          <PageHeader
            headerTitle="Primary Sales"
            renderRight={() => {
              return (
                <div className="col-auto d-flex">
                  <Button
                    variant="primary"
                    onClick={handleIsModal}
                    className="btn btn-primary"
                    disabled={buttonLoader.getDropDowns}
                  >
                    {buttonLoader.getDropDowns ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-1"
                      />
                    ) : (
                      <i className="icofont-plus-circle me-2 fs-6"></i>
                    )}
                    Add Primary Sale
                  </Button>
                </div>
              );
            }}
          />
          <div className="row g-3 row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1 row-cols-xl-1 row-cols-xxl-1 row-deck py-1 pb-4">
            {/* add filters here */}

            {allPrimarySales?.length > 0 ? (
              <div className="row clearfix g-3">
                <div className="card">
                  <div className="card-body">
                    <DataTable
                      columns={columnsT}
                      title={"Primary Sale"}
                      onChangePage={handlePageChange}
                      data={allPrimarySales}
                      defaultSortField="title"
                      pagination
                      selectableRows={false}
                      highlightOnHover={true}
                      dense
                      customStyles={customStyles}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="background-color: #3498db; color: #fff; text-align: center; padding: 10px;">
                <p className="font-size: 18px; font-weight: bold;">
                  No More Primary Sales To Load.
                </p>
              </div>
            )}
          </div>

          <Modal
            size="lg"
            centered
            show={isModal}
            onHide={() => {
              setEditData(null);
              setIsModal(false);
            }}
          >
            <Modal.Header closeButton show={isModal} onHide={handleIsModal}>
              <Modal.Title className="fw-bold">
                {editData ? "Edit Primary Sale" : "Add Primary Sale"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <PrimarySalesModal
                handleIsModal={handleIsModal}
                editData={editData}
                productData={content}
                addPrimarySales={addPrimarySales}
                updatePrimarySales={updatePrimarySales}
              />
            </Modal.Body>
          </Modal>

          <Modal
            show={isModalDelete}
            centered
            onHide={() => {
              setIsModalDelete(false);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title className="fw-bold">Delete Primary Sale</Modal.Title>
            </Modal.Header>
            <Modal.Body className="justify-content-center flex-column d-flex">
              <i className="icofont-ui-delete text-danger display-2 text-center mt-2"></i>
              <p className="mt-4 fs-5 text-center">
                You can delete this item Permanently
              </p>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleIsModalDelete}
              >
                Cancel
              </button>
              <Button
                variant="primary"
                className="btn btn-danger color-fff"
                onClick={handleDeleteProduct}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>

          <ModalLoader message={fetchMessage} show={modalLoader} />

          <div>
            <Toast show={showToast} onClose={() => setShowToast(false)}>
              <Toast.Header>
                <strong className="mr-auto">No More Sales to load</strong>
              </Toast.Header>
            </Toast>
          </div>
        </div>
      )}{" "}
    </>
  );
}

export default PrimarySales;
