import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Schema } from "./schema";
import Swal from "sweetalert2";
import {
  addProducts,
  updateProducts,
} from "../../../../redux/features/productsSlice";
// import { addProducts, updateProducts } from "../../../redux/features/productsSlice";

function PrimarySalesModal({
  editData,
  handleIsModal,
  productData,
  addPrimarySales,
  updatePrimarySales,
}) {
  const [buttonLoader, setButtonLoader] = useState(false);
  const dispatch = useDispatch();

  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(Schema) });

  console.log("editData", editData);

  useEffect(() => {
    if (editData) {
      reset(editData);
    }
  }, []);

  const handleDataChange = async (values) => {
    setButtonLoader(true);
    console.log(values);
    let payload = {
      ...values,
      updateAt: "",
    };
    if (editData) {
      payload = { ...payload, updateAt: new Date().toLocaleString() };
      // Update Primary Sale
      await updatePrimarySales(payload, handleIsModal);
    } else {
      // Add Primary Sale
      await addPrimarySales(payload, handleIsModal);
    }
    setButtonLoader(false);
    reset();
  };

  return (
    <form onSubmit={handleSubmit(handleDataChange)}>
      { !editData && <div className="mb-3">
        <label htmlFor="productId" className="form-label">
          Product Name
        </label>
        <select
          className="form-control"
          id="productId"
          {...register("productId")}
        >
          <option value="">Select Product</option>
          {productData
            ?.filter(
              (item, index, self) =>
                index === self.findIndex((t) => t.productId === item.productId)
            )
            ?.map((product) => (
              <option value={product?.productId}>{product?.name}</option>
            ))}
        </select>
        <p className="text-danger">{errors.productId?.message}</p>
      </div>}
      <div className="mb-3">
        <label htmlFor="quantity" className="form-label">
          Quantity
        </label>
        <input
          type="text"
          className="form-control"
          id="quantity"
          placeholder="Quantity"
          {...register("quantity")}
        />
        <p className="text-danger">{errors.quantity?.message}</p>
      </div>
      <div className="mb-3">
        <label htmlFor="salesLevel" className="form-label">
          Sales Level
        </label>
        <select
          className="form-control"
          id="salesLevel"
          {...register("salesLevel")}
        >
          <option value="">Select Sale Level</option>
          <option value="WAREHOUSE">Warehouse</option>
          <option value="RETAILER">Retailer</option>
          <option value="STOCKIST">Stockist</option>
        </select>
        <p className="text-danger">{errors.salesLevel?.message}</p>
      </div>
      <div className="w-100 d-flex gap-2 justify-content-end mt-4 mb-3">
        <button className="btn btn-secondary" onClick={handleIsModal}>
          Done
        </button>
        <button className="btn btn-primary" type="submit">
          {buttonLoader && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="me-1"
            />
          )}
          {editData ? "Update" : "Add"}
        </button>
      </div>
    </form>
  );
}

export default PrimarySalesModal;
