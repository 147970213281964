import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Schema } from "./schema";
import Swal from "sweetalert2";
import { addProducts, updateProducts } from "../../../../redux/features/productsSlice";
// import { addProducts, updateProducts } from "../../../redux/features/productsSlice";

function SecondarySalesModal({ editData, handleIsModal }) {
  const [buttonLoader, setButtonLoader] = useState(false);
  const dispatch = useDispatch();

  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(Schema) });

  console.log("editData", editData);

  useEffect(() => {
    if(editData){
      reset(editData);
    }
  }, [])

  const handleDataChange = async (values) => {
    setButtonLoader(true);
    console.log(values)
    if (editData) {
      try {
        // Update logic here
        handleIsModal()
        dispatch(updateProducts(values))
        Swal.fire("Success","Sale updated successfully", "success");
      } catch (error) {
        Swal.fire("Error", "Unable to Update Sale Details");
      }
    } else {
      try {
        handleIsModal();
        dispatch(addProducts(values))
        Swal.fire("Success","Sale added successfully", "success");
      } catch (error) {
        handleIsModal();
        console.log("Error Adding Sale", error);
        Swal.fire("Error", "Unable to Add Sale!");
      }
    }
    setButtonLoader(false);
    reset();
  };

  return (
    <form onSubmit={handleSubmit(handleDataChange)}>
      <div className="mb-3">
        <label htmlFor="name" className="form-label">
          Product Name
        </label>
        <input
          type="text"
          className="form-control"
          id="name"
          placeholder="Product Name"
          {...register("name")}
        />
        <p className="text-danger">{errors.name?.message}</p>
      </div>
      <div className="row g-3">
        <div className="col-lg-6">
          <label htmlFor="sku" className="form-label">
            SKU (Stock Keeping Unit)
          </label>
          <input
            type="text"
            className="form-control"
            id="sku"
            placeholder="Stock Keeping Unit"
            {...register("sku")}
          />
          <p className="text-danger">{errors.sku?.message}</p>
        </div>
        <div className="col-lg-6">
          <label htmlFor="unit_of_measurement" className="form-label">
            Measurement Unit
          </label>
          <select
            className="form-control"
            id="unit_of_measurement"
            {...register("unit_of_measurement")}
          >
            <option value="">Select Unit</option>
            <option value="kg">Kilogram (kg)</option>
            <option value="g">Gram (g)</option>
            <option value="mg">Milligram (mg)</option>
            <option value="lb">Pound (lb)</option>
            <option value="oz">Ounce (oz)</option>
          </select>
          <p className="text-danger">{errors.unit_of_measurement?.message}</p>
        </div>
      </div>
      <div className="mb-3">
        <label htmlFor="warehouse_price" className="form-label">
          Warehouse Price
        </label>
        <input
          type="text"
          className="form-control"
          id="warehouse_price"
          placeholder="Warehouse Price"
          {...register("warehouse_price")}
        />
        <p className="text-danger">{errors.warehouse_price?.message}</p>
      </div>
      <div className="row g-3">
        <div className="col-lg-6">
          <label htmlFor="stockist_price" className="form-label">
            Stockist Price
          </label>
          <input
            type="text"
            className="form-control"
            id="stockist_price"
            placeholder="Stockist Price"
            {...register("stockist_price")}
          />
          <p className="text-danger">{errors.stockist_price?.message}</p>
        </div>
        <div className="col-lg-6">
          <label htmlFor="retailer_price" className="form-label">
            Retailer Price
          </label>
          <input
            type="text"
            className="form-control"
            id="retailer_price"
            placeholder="Retailer Price"
            {...register("retailer_price")}
          />
          <p className="text-danger">{errors.retailer_price?.message}</p>
        </div>
      </div>
      <div className="mb-3">
        <label htmlFor="gst_percentage" className="form-label">
          GST Percentage
        </label>
        <input
          type="text"
          className="form-control"
          id="gst_percentage"
          placeholder="GST Percentage"
          {...register("gst_percentage")}
        />
        <p className="text-danger">{errors.gst_percentage?.message}</p>
      </div>
      <div className="w-100 d-flex gap-2 justify-content-end mt-4 mb-3">
        <button className="btn btn-secondary" onClick={handleIsModal}>
          Done
        </button>
        <button className="btn btn-primary" type="submit">
          {buttonLoader && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="me-1"
            />
          )}
          {editData ? "Update" : "Add"}
        </button>
      </div>
    </form>
  );
}

export default SecondarySalesModal;
