import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { permissionIds } from "../constants/constants";
import { addPrimarySale, deleteAllPrimarySale, setPrimarySale, updatePrimarySale } from "../redux/features/primarySalesSlice";
import { addNewPrimarySales, getAllPrimarySales, UpdatePrimarySales } from "../api/sales/primarySales/primarySales-api";

export function usePrimarySalesHook() {
  const dispatch = useDispatch();
  const primarySales = useSelector((state) => state.PrimarySales);
  const { allPrimarySales, paginationData } = primarySales;
  const MemberPermission = useSelector((state) => state.Permission.memberPermissions);
  const Cred = useSelector((state) => state.Cred);

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [sizeNumber, setSizeNumber] = useState(20);

  const setPage = (pageNumber) => setPageNumber(pageNumber);
  const setSize = (sizeNumber) => setSizeNumber(sizeNumber);

  async function getAllPrimarySale() {
    if (MemberPermission.some(
      (item) =>
        item === permissionIds.manager || item === permissionIds.viewManager
    )) {
      setIsLoading(true);
      try {
        if (allPrimarySales.length === 0) {
          dispatch(deleteAllPrimarySale());
          const PrimarySale = await getAllPrimarySales(Cred.token, pageNumber, sizeNumber);
          if (PrimarySale.content.length >= 0) {
            dispatch(setPrimarySale({
              allPrimarySales: PrimarySale.content,
              paginationData: {
                page: PrimarySale.page,
                totalElements : PrimarySale.totalElements,
                totalPages : PrimarySale.totalPages
              },
            }));
          }
        }
      } catch (error) {
        Swal.fire({
          title: "Something went wrong!",
          text: "Can't Fetch Sales! Please try After Some Time",
          icon: "error",
        });
        setIsError(true);
        console.log("Error fetching Primary Sales ::", error)
      } finally {
        setIsLoading(false);
      }
    }
  }

  useEffect(() => {
    if (Cred) {
      getAllPrimarySale();
    }
  }, [pageNumber, sizeNumber, Cred]);

  async function addPrimarySales(payload, handleIsModal) {
    try {
      if(!payload){
        handleIsModal()
        await Swal.fire({
          title: "Incomplete Data!",
          text: "Please fill all the mandatory details",
          icon: "warning"
        })
        handleIsModal()
        return;
      };
      const resp = await addNewPrimarySales(payload, Cred.token);
      if(resp.status >= 200 && resp.status < 300){
        dispatch(addPrimarySale(payload))
        handleIsModal();
        Swal.fire({
          title: "Successfull",
          text: "Primary Sale added Successfully",
          icon: "success",
        });
      }
    } catch (error) {
      handleIsModal();
      await Swal.fire({
        title: "Something went wrong!",
        text: "Can't Add Sales! Please try After Some Time",
        icon: "error",
      });
      handleIsModal();
      setIsError(true);
      console.log("Error Adding Primary Sales ::", error)
    } finally {
      setIsLoading(false);
    }
  }


  async function updatePrimarySales(payload, handleIsModal) {
    try {
      if(!payload.productId || !payload.quantity || !payload.salesLevel){
        handleIsModal()
        await Swal.fire({
          title: "Incomplete Data!",
          text: "Please fill all the mandatory details",
          icon: "warning"
        })
        handleIsModal()
        return;
      };

      const data = {
        salesLevel: payload.salesLevel,
        quantitySold : payload.quantity
      }
      const resp = await UpdatePrimarySales(data, payload.productId, Cred.token);
      if(resp.status >= 200 && resp.status < 300){
        dispatch(updatePrimarySale(payload))
        handleIsModal();
        Swal.fire({
          title: "Successfull",
          text: "Primary Sale updated Successfully",
          icon: "success",
        });
      }
    } catch (error) {
      handleIsModal();
      await Swal.fire({
        title: "Something went wrong!",
        text: error.response.data.message ? error.response.data.message :  "Can't Update Sales! Please try After Some Time",
        icon: "error",
      });
      handleIsModal();
      setIsError(true);
      console.log("Error Updating Primary Sales ::", error)
    } finally {
      setIsLoading(false);
    }
  }



  async function deletePrimarySales() {}
  async function getPrimarySales() {}

  return {
    getAllPrimarySale,
    getPrimarySales,
    addPrimarySales,
    updatePrimarySales,
    deletePrimarySales,
    pageNumber,
    sizeNumber,
    setPage,
    setSize,
    isLoading,
    isError,
  };
}
