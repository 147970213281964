import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, Link } from "react-router-dom";
import Sidebar from "./components/common/Sidebar";
import AuthIndex from "./screens/AuthIndex";
import MainIndex from "./screens/MainIndex";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import {
  setCredentials,
  deleteCredentials,
} from "./redux/features/credentialSlice";

import { constants } from "./constants/constants";
import Loading from "./components/UI/Loading";
import { jwtDecode } from "jwt-decode";
const Home = () => <h2>Home Page</h2>;

// About component
const About = () => <h2>About Page</h2>;

function App(props) {
  const Cred = useSelector((state) => state.Cred);
  const history = useNavigate();
  const [loading, setloading] = useState(false);
  function activekey() {
    var res = window.location.pathname;
    var baseUrl = process.env.PUBLIC_URL;
    baseUrl = baseUrl.split("/");
    res = res.split("/");
    res = res.length > 0 ? res[baseUrl.length] : "/";
    res = res ? "/" + res : "/";
    const activeKey1 = res;
    return activeKey1;
  }
  const Dispatch = useDispatch();
  async function getCred() {
    setloading(true);
    try {
      const token = await window.localStorage.getItem(constants.token_store);
      const decode = await jwtDecode(token);
      if (token != null) {
        Dispatch(setCredentials({ ...decode, token: token }));
      } else {
        history("/");

        Dispatch(deleteCredentials());
      }
    } catch (error) {
      history("/");

      Dispatch(deleteCredentials());
    }
    setloading(false);
  }
  useEffect(() => {
    getCred();
  }, []);
  if (loading) {
    return <Loading animation={"border"} color={"yellow"} />;
  }
  return (
    <>
      {Cred.token ? (
        <div id="mytask-layout" className="theme-indigo">
          <Sidebar activekey={activekey()} history={props.history} />
          <MainIndex activekey={activekey} />
        </div>
      ) : (
        <div id="mytask-layout" className="theme-indigo">
          <AuthIndex />
        </div>
      )}
    </>
  );
}

export default App;


