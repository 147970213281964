import * as yup from 'yup';

export const Schema = yup.object().shape({
  name: yup.string().required("Product name is required"),
  sku: yup.string().required("SKU is required"),
  unit_of_measurement: yup.string().required("Measurement unit is required"),
  warehouse_price: yup
    .number()
    .positive("Warehouse Price must be positive")
    .required("Warehouse is required"),
  stockist_price: yup
    .number()
    .positive("Warehouse Price must be positive")
    .required("Warehouse is required"),
  retailer_price: yup
    .number()
    .positive("Warehouse Price must be positive")
    .required("Warehouse is required"),
  gst_percentage: yup
    .number(2)
    .positive("Warehouse Price must be positive")
    .required("Warehouse is required"),
});