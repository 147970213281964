export const constants={
    token_store:"PRISM_SFA_JWT_TOKEN",
    website_name:"Prism-SFA"
}



export const permissionIds = {
    createManager: "Create_Manager",
    editManager:"Edit_Manager",
    deleteManager:"Delete_Manager",
    manager:"Manager",
    viewManager:"View_Manager",
}

export const permissionEnum = [
    { label: 'Create_Manager' , value: 0},
    { label: 'Edit_Manager' , value: 1},
    { label: 'Delete_Manager' , value: 2},
    { label: 'Manager' , value: 3},
    { label: 'View_Manager' , value: 4},
]