import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner, Toast } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import Loading from "../../../components/UI/Loading";
import PageHeader from "../../../components/common/PageHeader";
import ModalLoader from "../../UIComponents/ModalLoader";
import SecondarySalesModal from "./form/Modal";
function SecondarySales() {
  const [isModal, setIsModal] = useState(false);
  const [isModalDelete, setIsModalDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  // data store variable
  const [editData, setEditData] = useState(null);
  const [deleteData, setDeleteData] = useState(null);

  const [fetchMessage, setFetchMessage] = useState("");

  // redux selectors
  const dispatch = useDispatch();
  const Product = useSelector((state) => state.Products);

  const [modalLoader, setModalLoader] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [buttonLoader, setButtonLoader] = useState({
    getDropDowns: false,
    addProduct: false,
  });
  const [length, setLength] = useState(0);

  // modal change function
  function handleIsModalDelete() {
    setIsModalDelete(!isModalDelete);
  }
  function handleIsModal() {
    setIsModal(!isModal);
  }

  const handlePageChange = async (NextPage) => {
    if (
      // Holiday.paginationData.totalPages - 1 == Holiday.paginationData.number ||
      // Holiday.paginationData.number > Holiday.paginationData.totalPages
      console.log(NextPage)
    ) {
      return;
    }

    try {
      // const resp = await getAllHolidays(
      //   Cred.token,
      //   Holiday.paginationData.number + 1
      // );
      // Dispatch(concatHoliday({
      //     allHoliday: resp.data,
      //     paginationData: resp.paginationData,
      //   }));
    } catch (error) {
      Swal.fire("Something Went Wrong", "Can't Fetch More Data");
    }
  };

  var columnsT = "";

  columnsT = [
    {
      name: "PRODUCT ID",
      selector: (row) => (
        <span className={row.txtClass}>{row.id ? row.id : "NA"}</span>
      ),
      sortable: true,
    },
    {
      name: "PRODUCT NAME",
      selector: (row) => (
        <span className={row.txtClass}>{row.name ? row.name : "NA"}</span>
      ),
      sortable: true,
    },
    {
      name: "SKU (Stock Keeping Unit)",
      selector: (row) => (
        <span className={row.txtClass}>{row.sku ? row.sku : "NA"}</span>
      ),
      sortable: true,
    },
    {
      name: "Measurement Unit",
      selector: (row) => (
        <span className={row.txtClass}>
          {row.unit_of_measurement ? row.unit_of_measurement : "NA"}
        </span>
      ),
      sortable: true,
    },
    {
      name: "GST PERCENTAGE (%)",
      selector: (row) => (
        <span className={row.txtClass}>{row.gst_percentage ? row.gst_percentage + " %" : "NA"}</span>
      ),
      sortable: true,
    },
    {
      name: "RETAILER PRICE",
      selector: (row) => (
        <span className={row.txtClass}>
          {row.retailer_price ? row.retailer_price : "NA"}
        </span>
      ),
      sortable: true,
    },
    {
      name: "STOCKIST PRICE",
      selector: (row) => (
        <span className={row.txtClass}>
          {row.stockist_price ? row.stockist_price : "NA"}
        </span>
      ),
      sortable: true,
    },
    {
      name: "WAREHOUSE PRICE",
      selector: (row) => (
        <span className={row.txtClass}>
          {row.warehouse_price ? row.warehouse_price : "NA"}
        </span>
      ),
      sortable: true,
    },
    {
      name: "ACTION",
      selector: () => {},
      sortable: true,
      cell: (row) => (
        <div
          className="btn-group"
          role="group"
          aria-label="Basic outlined example"
        >
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={() => {
              setEditData(row)
              handleIsModal();
            }}
          >
            <i className="icofont-edit text-success"></i>
          </button>
          <button
            type="button"
            onClick={() => {
              setDeleteData(row)
              handleIsModalDelete();
            }}
            className="btn btn-outline-secondary deleterow"
          >
            <i className="icofont-ui-delete text-danger"></i>
          </button>
        </div>
      ),
    },
  ];

  const handleDeleteProduct = async ( id = deleteData?.id) => {
    try {
      handleIsModalDelete();
      // dispatch(deleteProducts(data.name));
      Swal.fire(
        "Product Deleted",
        "This Product is deleted successfully",
        "success"
      );
    } catch (error) {
      console.log("Error:: ", error);
      handleIsModalDelete();
      Swal.fire("Unable to delete this product", "error");
    }
  };

  return (
    <>
      {loading ? (
        <Loading animation={"border"} color={"black"} />
      ) : (
        <div className="container-xxl">
          <PageHeader
            headerTitle="Secondary Sales"
            renderRight={() => {
              return (
                <div className="col-auto d-flex">
                  <Button
                    variant="primary"
                    onClick={handleIsModal}
                    className="btn btn-primary"
                    disabled={buttonLoader.getDropDowns}
                  >
                    {buttonLoader.getDropDowns ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-1"
                      />
                    ) : (
                      <i className="icofont-plus-circle me-2 fs-6"></i>
                    )}
                    Add Secondary Sale
                  </Button>
                </div>
              );
            }}
          />
          <div className="row g-3 row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1 row-cols-xl-1 row-cols-xxl-1 row-deck py-1 pb-4">

            {/* add filters here */}


            {Product.allProducts?.length > 0 ? (

              <div className="row clearfix g-3">
                <div className="card">
                  <div className="card-body">
                    <DataTable
                      columns={columnsT.map((column) => ({
                        ...column,
                        width: `${column.name.length * 10 + 40}px`,
                        wrap: true,
                      }))}
                      title={Product.allProducts.name}
                      onChangePage={handlePageChange}
                      data={Product.allProducts}
                      defaultSortField="title"
                      pagination
                      selectableRows={false}
                      className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
                      highlightOnHover={true}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="background-color: #3498db; color: #fff; text-align: center; padding: 10px;">
                <p className="font-size: 18px; font-weight: bold;">
                  No More Secondary Sales To Load.
                </p>
              </div>
            )}
          </div>


          <Modal
            size="xl"
            centered
            show={isModal}
            onHide={() => {
              setIsModal(false);
            }}
          >
            <Modal.Header closeButton show={isModal} onHide={handleIsModal}>
              <Modal.Title className="fw-bold">
                {editData ? "Edit Secondary Sale" : "Add Secondary Sale"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <SecondarySalesModal handleIsModal={handleIsModal} editData={editData} />
            </Modal.Body>
          </Modal>

          <Modal
            show={isModalDelete}
            centered
            onHide={() => {
              setIsModalDelete(false);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title className="fw-bold">Delete Secondary Sale</Modal.Title>
            </Modal.Header>
            <Modal.Body className="justify-content-center flex-column d-flex">
              <i className="icofont-ui-delete text-danger display-2 text-center mt-2"></i>
              <p className="mt-4 fs-5 text-center">
                You can delete this item Permanently
              </p>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleIsModalDelete}
              >
                Cancel
              </button>
              <Button
                variant="primary"
                className="btn btn-danger color-fff"
                onClick={handleDeleteProduct}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>

          {/* <ModalLoader message={fetchMessage} show={modalLoader} /> */}

          {Product.allProducts?.totalPages &&
            Product.allProducts?.totalPages - 1 > Product.allProducts?.number && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    // onEndReach();
                    setLength(length - 10);
                  }}
                  style={{ width: "200px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p style={{ fontSize: 18, marginBottom: -2 }}>Load More</p>
                    {loadMore && (
                      <Spinner
                        animation="border"
                        size="sm"
                        style={{ marginLeft: "10px" }}
                      />
                    )}
                  </div>
                </Button>
              </div>
            )}

          <div>
            <Toast show={showToast} onClose={() => setShowToast(false)}>
              <Toast.Header>
                <strong className="mr-auto">No More Sales to load</strong>
              </Toast.Header>
            </Toast>
          </div>
        </div>
      )}{" "}
    </>
  );
}

export default SecondarySales;
