import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Spinner, Toast } from "react-bootstrap";
import PageHeader from "../../components/common/PageHeader";
import AllocatedTask from "../../components/Projects/AllocatedTask";
import RecentActivity from "../../components/Projects/RecentActivity";
import TaskProgress from "../../components/Projects/TaskProgress";
import { ImCross } from "react-icons/im";
import { debounce } from "lodash";

import {
  CompletedData,
  InProgressTaskData,
  needReviewData,
} from "../../components/Data/AppData";
import TaskNestable1 from "../../components/Projects/TaskNestable1";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { exportToExcel } from "../../helper/exportFunction";
import { setMembers, concatMembers } from "../../redux/features/memberSlice";
import {
  getAllMemberProjection,
  getAllMembers,
} from "../../api/member/member-api";
import { useDispatch } from "react-redux";
import {
  getAllClientByCities,
  getAllClientProjection,
  getAllClients,
} from "../../api/clients/clients-api";
import {
  createSchedules,
  getAllSchedules,
  getSchedulesByDate,
} from "../../api/schedules/schedules-api";

import {
  setSchedules,
  addSchedules,
  updateSchedules,
  deleteSchedules,
  concatSchedules,
} from "../../redux/features/schedulesSlice";

import {
  setDropDownMembers,
  addDropDownMembers,
  deleteAllDropDownMembers,
  updateDropDownMembers,
  deleteDropDownMembers,
  concatDropDownMembers,
} from "../../redux/features/dropdownMemberSlice";

import {
  setDropDowns,
  addDropDowns,
  deleteAllDropDowns,
  updateDropDowns,
  deleteDropDowns,
  concatDropDowns,
} from "../../redux/features/dropdownClientSlice";

import {
  setClients,
  addClients,
  concatClients,
} from "../../redux/features/clientSlice";

import Loading from "../../components/UI/Loading";
import {
  getIDFromSchedules,
  getInProgressSchedules,
  mergeEmployeeNamesSchedules,
} from "../../helper/array-sort";
import { json } from "react-router-dom";
import Select from "react-select";
import Popup from "reactjs-popup";
import DataTable from "react-data-table-component";
import MultiDropdown from "../../components/UI/MultiDropdown";
import SingleDropdown from "../../components/UI/SingleDropdown";
import { data } from "browserslist";
import { getDateFormat } from "../../helper/date-functions";
import axios from "axios";

function Schedules() {
  const [isModal, setIsModal] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const Cred = useSelector((state) => state.Cred);
  const [cityDropdown, setCityDropdown] = useState([]);
  const cityDropRef = useRef(null);
  const Dispatch = useDispatch();
  const [selectedDays, setSelectedDays] = useState([]);
  const [clientDropdown, setClientDropdown] = useState([]);
  const [selectedMember, setSelectedMember] = useState({});
  const [isOpenMember, setIsOpenMember] = useState(false);
  const [isOpenClient, setIsOpenClient] = useState(false);
  const [isOpenCity, setIsOpenCity] = useState(false);
  const [startAt, setStartAt] = useState("");
  const [endAt, setEndAt] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState([]);
  const AllSchedules = useSelector((state) => state.Schedules);
  const [allSchedule, setAllSchedule] = useState([]);
  const [cancelToken, setCancelToken] = useState(undefined);
  const Member = useSelector((state) => state.Member);
  const [buttonLoader, setButtonLoader] = useState({
    addSchedule: false,
    gettingDropDowns: false,
    loadMoreMember: false,
    loadMoreClient: false,
    createSchedules: false,
    loadMoreSchedule: false,
  });

  const [option, setOption] = useState([]);

  async function getDays(clientArray) {
    try {
      // console.log(clientArray);
      if (Array.isArray(clientArray) && clientArray.length > 0) {
        const values = clientArray.flatMap((item) =>
          item.daysAvailability.map((day) => ({ value: day, label: day }))
        );
        // console.log(values)
        const uniqueDays = values.filter((day, index) => values.findIndex(d => d.label === day.label) === index);
        // console.log(values)
        return uniqueDays;
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: "Something went wrong!",
        text: "Can't Fetch Days Available. Please try After Some Time",
        icon: "error",
      });
      return [];
    }
  }
  

  const [cities, setCities] = useState([]);

  async function getData() {
    setLoading(true);
    try {
      if (AllSchedules.allSchedule.length <= 0) {
        const resp = await getAllSchedules(0, Cred.token, Cred.sub);
        // console.log(resp)
        Dispatch(
          setSchedules({
            allSchedule: resp.data.reverse(),
            paginationData: resp.paginationData,
          })
        );
      }
    } catch (error) {
      Swal.fire({
        title: "Something went gone!",
        text: "Can't Fetch Schedules. Please try After Some Time",
        icon: "error",
      });
    }
    setLoading(false);
  }
  useEffect(() => {
    getData();
  }, []);

  async function getMember() {
    if (Member.allMembers.length <= 0) {
      const Arrays = await getAllMembers(0, Cred.token, Cred.sub);
      Dispatch(
        setMembers({
          allMembers: Arrays.data,
          paginationData: Arrays.paginationData,
        })
      );
    }
  }
  async function getClient(cit) {
    try {
      if (cancelToken !== undefined && cancelToken.cancel) {
        cancelToken.cancel();
      }
      const newCancelToken = axios.CancelToken.source();
      setCancelToken(newCancelToken);
      const resp = await getAllClientByCities(
        Cred.token,
        cit,
        selectedMember.id,
        newCancelToken.token
      );
      setClientDropdown(resp);
    } catch (error) {
      if (axios.isCancel(error)) {
      } else {
        setIsModal(false);
        Swal.fire("Something went wrong", "Please try again", "error");
      }
    }
  }
  async function openModal() {
    try {
      setIsModal(true);

      await getMember();
      setButtonLoader({
        ...buttonLoader,
        ...{ gettingDropDowns: false },
      });
    } catch (error) {
      setIsModal(false);

      Swal.fire({
        title: "Something went wrong!",
        text: "Can't Necessary Details!. Please try After Some Time",
        icon: "error",
      });
    }
  }

  const toggleDropdownMember = () => {
    if (isOpenClient) setIsOpenClient(false);
    if (isOpenCity) setIsOpenCity(false);
    setIsOpenMember(!isOpenMember);
  };
  const toggleDropdownClient = () => {
    if (isOpenMember) setIsOpenMember(false);
    if (isOpenCity) setIsOpenCity(false);
    setIsOpenClient(!isOpenClient);
  };
  const toggleDropdownCity = () => {
    if (isOpenCity) setIsOpenCity(false);
    if (isOpenMember) setIsOpenMember(false);
    if (isOpenClient) setIsOpenClient(false);
    setIsOpenCity(!isOpenCity);
  };

  async function onEndReachMember() {
    setButtonLoader({
      ...buttonLoader,
      ...{ loadMoreMember: true },
    });
    try {
      const resp = await getAllMembers(
        Member.paginationData.number + 1,
        Cred.token,
        Cred.sub
      );
      Dispatch(
        concatMembers({
          allMembers: resp.data,
          paginationData: resp.paginationData,
        })
      );
    } catch (error) {
      Swal.fire({
        title: "Something went wrong",
        text: "Can't Fetch More Member. Please Try After Some Time.",
        icon: "error",
        timer: 2000,
      });
    }
    setButtonLoader({
      ...buttonLoader,
      ...{ loadMoreMember: false },
    });
  }
  async function CreateSchedules() {
    try {
      if (allSchedule.length > 0) {
        setButtonLoader({
          ...buttonLoader,
          ...{ createSchedules: true },
        });
        console.log("allSchedule: ", allSchedule)
        const resp = await createSchedules(Cred.token, allSchedule);
        let dispSch = [];
        allSchedule.forEach((item, index) =>
          dispSch.push({ ...item, id: resp[index] })
        );
        // console.log("dispSch",dispSch)
        Dispatch(
          concatSchedules({
            allSchedule: dispSch,
            paginationData: AllSchedules.paginationData,
          })
        );
        setAllSchedule([]);
        setIsModal(false);
        setButtonLoader({
          ...buttonLoader,
          ...{ createSchedules: false },
        });
        Swal.fire(
          "Successfully Created",
          "Your Schedules Has Been Created",
          "success"
        );
        return;
      } else {
        setIsModal(false);
        Swal.fire({
          title: "Incomplete Fields",
          text: "Please give value to each field",
          icon: "warning",
          timer: 2000,
        }).then((e) => setIsModal(true));
      }
    } catch (error) {
      setIsModal(false);
      Swal.fire({
        title: "Something went wrong",
        text: "Can't Create Schedules. Please Try After Some Time.",
        icon: "error",
        timer: 2000,
      });
    }
    setButtonLoader({
      ...buttonLoader,
      ...{ createSchedules: false },
    });
  }

  let column = [
    {
      name: "Member",
      selector: (row) => row.members.id,
      sortable: true,
      cell: (row) => (
        <p className="fw-bold text-secondary">
          {row.members.firstName + " " + row.members.lastName}
        </p>
      ),
    },
    {
      name: "Start At",
      selector: (row) => row.startAt,
      sortable: true,
      cell: (row) => (
        <p className="fw-bold text-secondary">{getDateFormat(row.startAt)}</p>
      ),
    },
    {
      name: "End At",
      selector: (row) => row.endAt,
      sortable: true,
      cell: (row) => (
        <p className="fw-bold text-secondary">{getDateFormat(row.endAt)}</p>
      ),
    },
    {
      name: "Cities",
      selector: (row) => row.cities,
      sortable: true,
      cell: (row) => (
        <p className="fw-bold text-secondary">
          {row.cities.length > 0
            ? row.cities.map((city) => city.cityName + ",\n")
            : "No City"}
        </p>
      ),
    },
    {
      name: "Client",
      selector: (row) => row.clients[0].id,
      sortable: true,
      cell: (row) => {
        return (
          <p>
            {row.clients.length > 0
              ? row.clients.map(
                  (client) =>
                    (client.clientFirstName + " " + client.clientLastName)
                      .length > 3 &&
                    client.clientFirstName + " " + client.clientLastName + ",\n"
                )
              : "No Client"}
          </p>
        );
      },
    },
    {
      name: "Action",
      cell: (row, index) => (
        <button
          type="button"
          onClick={() => {
            const newSch = allSchedule.filter((sche, ind) => index != ind);
            setAllSchedule(newSch);
          }}
          className="btn btn-outline-secondary"
        >
          <i className="icofont-ui-delete text-danger"></i>
        </button>
      ),
    },
  ];
  // const debouncedGetClient = debounce(getClient, 1400);

  // async function checkDaysAvailability(startAt, endAt) {
  //   try {
  //     const days = [
  //       "Monday",
  //       "Tuesday",
  //       "Wednesday",
  //       "Thursday",
  //       "Friday",
  //       "Saturday",
  //       "Sunday",
  //     ];
  //     startAt = new Date(startAt);
  //     endAt = new Date(endAt);
  
  //     let check = 0;
  
  //     // Iterate over each client in the selectedClient array
  //     for (const client of selectedClient) {
  //       if (Array.isArray(client.daysAvailability) && client.daysAvailability.length > 0) {
  //         client.daysAvailability.forEach((item) => {
  //           for (
  //             let currentDate = startAt;
  //             currentDate <= endAt;
  //             currentDate.setDate(currentDate.getDate() + 1)
  //           ) {
  //             if (days[currentDate.getDay()] === item) {
  //               check = check + 1;
  //             }
  //           }
  //         });
  //       } else {
  //         console.log("Error on checking Days for client", client);
  //         return false;
  //       }
  //     }
  
  //     if (check <= 0) {
  //       Swal.fire(
  //         "Invalid Time Period",
  //         "Client is not available between selected time period",
  //         "warning"
  //       );
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   } catch (error) {
  //     Swal.fire("Something went wrong", "Please try again", "error");
  //     return false;
  //   }
  // }
  

  async function onEndReach() {
    setLoadMore(true);
    try {
      const resp = await getAllSchedules(
        AllSchedules.paginationData.number + 1,
        Cred.token,
        Cred.sub
      );
      
      if (resp.data.length > 0) {
        Dispatch(
          concatSchedules({
            allSchedule: resp.data,
            paginationData: resp.paginationData,
          })
        );
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: "Something went wrong",
        text: "Can't Fetch More Schedules. Please Try After Some Time.",
        icon: "error",
        timer: 2000,
      });
    }
    setLoadMore(false);
  }

  return (
    <>
      {loading ? (
        <Loading color="black" animation={"border"} />
      ) : (
        <div className="container-xxl">
          <PageHeader
            headerTitle="All Schedules"
            renderRight={() => {
              return (
                <div className="col-auto d-flex w-sm-100">
                  <button
                    className="btn btn-dark btn-set-task w-sm-100"
                    onClick={openModal}
                  >
                    {buttonLoader.gettingDropDowns ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-1"
                      />
                    ) : (
                      <i className="icofont-plus-circle me-2 fs-6"></i>
                    )}
                    Create Plan
                  </button>
                  <button
                    className="btn btn-dark btn-set-task w-sm-100 mx-2"
                    onClick={async () => {
                      try {
                        let mainData = [];
                        AllSchedules.allSchedule.map((item, index) => {
                          mainData.push({
                            "EMPLOYEE NAME": item.employeeName,
                            "LEAVE TYPE":
                              item.leaveType === 1
                                ? "Flexible leave"
                                : item.leaveType === 2
                                ? "Unpaid leave"
                                : "Sick leave",
                            FROM: item.startAt,
                            TO: item.endAt,
                            REASON: item.reason,
                            ACTION:
                              item.status === 0
                                ? "Pending"
                                : item.status === 1
                                ? "Approved"
                                : "Rejected",
                          });
                        });
                        exportToExcel(mainData);
                      } catch (error) {
                        Swal.fire(
                          "Something went wrong",
                          "Can't Export Necessary Data",
                          "error"
                        );
                      }
                    }}
                  >
                    <img
                      src="reply-solid.svg"
                      className="mx-2"
                      style={{ transform: "scaleX(-1)", height: "18px" }}
                    />
                    Export
                  </button>
                </div>
              );
            }}
          />
          <div className="row clearfix g-3">
            <div className="col-lg-12 col-md-10 flex-column mt-n20">
              {AllSchedules.allSchedule.length > 0 ? (
                <TaskNestable1 />
              ) : (
                <div className="background-color: #3498db; color: #fff; text-align: center; padding: 10px;">
                  <p className="font-size: 18px; font-weight: bold;">
                    No More Schedule's To Load.
                  </p>
                </div>
              )}
            </div>
          </div>
          <Modal
            size="lg"
            show={isModal}
            centered
            onHide={() => {
              setIsModal(false);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title className="fw-bold">Create Task</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <SingleDropdown
                buttonLoader={buttonLoader.loadMoreMember}
                title="Assign Member"
                isMandatory={true}
                data={selectedMember}
                accessLabel={(item) => item.firstName + " " + item.lastName}
                dropdownData={
                  Array.isArray(Member.allMembers)
                    ? [
                        ...Member.allMembers,
                        {
                          id: Cred.sub,
                          firstName: Cred.firstName,
                          lastName: Cred.lastName,
                          cities: Cred.cities,
                        },
                      ]
                    : []
                }
                isPagination={
                  Member.paginationData.totalPages &&
                  Member.paginationData.totalPages - 1 >
                    Member.paginationData.number
                }
                loadMore={onEndReachMember}
                handleChange={(item) => {
                  setSelectedMember(item);
                  setCityDropdown(item.cities);
                }}
                handleClick={() => {
                  setSelectedDays([]);
                  setSelectedClient([]);
                  setCities([]);
                  setStartAt("");
                  setEndAt("");
                  setClients([]);
                  setOption([]);
                }}
              />

              <br />
              <div className="row g-3">
                <div className="col-sm-6">
                  <label className="form-label">Start At*</label>
                  <input
                    type="date"
                    className="form-control"
                    id="datepickerded"
                    min={getDateFormat(new Date())}
                    value={startAt}
                    onChange={(e) => setStartAt(e.target.value)}
                  />
                </div>
                <div className="col-sm-6">
                  <label htmlFor="datepickerdedone" className="form-label">
                    End Date*
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    value={endAt}
                    min={startAt ? startAt : getDateFormat(new Date())}
                    id="datepickerdedone"
                    onChange={(e) => setEndAt(e.target.value)}
                  />
                </div>
              </div>
              <br />
              <MultiDropdown
                isMandatory={true}
                isPagination={false}
                dropdownRef={cityDropRef}
                loadMore={onEndReachMember}
                title="City"
                data={cities}
                dropdownData={Array.isArray(cityDropdown) ? cityDropdown : []}
                handleChange={(data) => {
                  setCities(data);
                  getClient(data);
                }}
              />

              <br />
              <div className="col-sm-12">
                <p className="form-label">Select Client*</p>
                <div className="custom-dropdown">
                  <div
                    id="assignClient"
                    className="multiDropdown"
                    onClick={toggleDropdownClient}
                  >
                    <div className="multiDropdownSubHeader">
                      {selectedClient.length > 0 ? (
                        selectedClient.map((e, i) => (
                          <p className="multiDropdownHeaderList" key={i}>
                            {e.clientFirstName} {e.clientLastName}
                            <ImCross
                              onClick={() => {
                                setSelectedClient(
                                  selectedClient.filter((c) => c.id != e.id)
                                );
                              }}
                              className="multiDropdownHeaderListCross"
                              size={8}
                            />
                          </p>
                        ))
                      ) : (
                        <p className="multiSelectNotSelected">Select Client</p>
                      )}
                    </div>
                    <i className="icofont-caret-down me-2 fs-6"></i>
                  </div>
                  {isOpenClient && (
                    <div className="dropdown-list">
                      {true ? (
                        clientDropdown.map((item, index) => (
                          <div
                            key={item.id}
                            onClick={async () => {
                              if (
                                !selectedClient.some((e, i) => e.id === item.id)
                              ) {
                                setSelectedClient([...selectedClient, item]);
                              }
                              await getDays(selectedClient);
                              setIsOpenCity(false);
                            }}
                            className={`dropdown-item ${
                              selectedClient.some((e, i) => e.id === item.id)
                                ? "selected"
                                : ""
                            }`}
                          >
                            {item.clientFirstName} {item.clientLastName}
                          </div>
                        ))
                      ) : (
                        <div
                          onClick={() => {
                            setIsOpenClient(false);
                          }}
                          className={"dropdown-item"}
                        >
                          No Client
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <br />
                <div className="row g-3">
                  <div
                    className="col-sm-9"
                    onClick={async () => {
                      if (isOpenCity) setIsOpenCity(false);
                      if (isOpenMember) setIsOpenMember(false);
                      if (isOpenClient) setIsOpenClient(false);

                      // console.log("not working 1",)
                      if (
                        Array.isArray(selectedClient) &&
                        selectedClient.length > 0
                      ) {
                        const resp = await getDays(selectedClient);
                        // console.log("resp",resp)
                        // console.log(selectedClient[0].daysAvailability)
                        setOption(resp);
                      } else {
                        setOption([]);
                        // console.log("not Working")
                      }

                      // Array.isArray(selectedClient) && selectedClient.length > 0
                    }}
                  >
                    <label
                      className="form-label"
                      htmlFor="exampleFormControlInput684"
                    >
                      Days availability*
                    </label>
                    <Select
                      inputId="exampleFormControlInput684"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          backgroundColor: "#eeeeee",
                          border: "none",
                        }),
                        placeholder: (baseStyles, state) => ({
                          ...baseStyles,
                          fontSize: "16",
                          color: "black",
                        }),
                        multiValueLabel: (baseStyles, state) => ({
                          ...baseStyles,
                          backgroundColor: "#4361ee",
                          color: "white",
                        }),
                        multiValueRemove: (baseStyles, state) => ({
                          ...baseStyles,
                          backgroundColor: "#4361ee",
                          color: "white",
                        }),
                      }}
                      onChange={(e) => setSelectedDays(e)}
                      options={option}
                      isMulti
                      defaultValue={selectedDays}
                      value={selectedDays}
                      placeholder="Select Available days"
                      noOptionsMessage={() => "Not found"}
                    />
                  </div>
                </div>
                <br />
                <button
                  type="button"
                  onClick={async () => {
                    try {
                      setButtonLoader({
                        ...buttonLoader,
                        ...{ addSchedule: true },
                      });

                      // const checkAvalability = await checkDaysAvailability(
                      //   startAt,
                      //   endAt
                      // );

                      // console.log(checkAvalability)
                      // if (!checkAvalability) {
                      //   setIsModal(false);
                      //   Swal.fire(
                      //     "Invalid Time Period",
                      //     "Client is unavailable in these dates! Please Fill different Dates",
                      //     "warning"
                      //   ).finally(() => setIsModal(true));
                      //   return;
                      // }

                      if (
                        !startAt ||
                        !endAt ||
                        !selectedMember ||
                        selectedDays.length <= 0 ||
                        !selectedClient
                      ) {
                        setIsModal(false);
                        Swal.fire(
                          "Incomplete Form",
                          "Please Fill the form",
                          "warning"
                        ).finally(() => setIsModal(true));
                      } else {
                        const st = new Date(startAt);
                        const et = new Date(endAt);

                        setAllSchedule([
                          ...allSchedule,
                          {
                            members: {
                              id: selectedMember.id,
                              firstName: selectedMember.firstName,
                              lastName: selectedMember.lastName,
                            },
                            days: selectedDays.map((day) => day.label),
                            cities: cities,
                            startAt: st.toISOString(),
                            endAt: et.toISOString(),
                            isApproved: Cred.sub == selectedMember.reportingManager ? 0 : 2,
                            approvedBy: Cred.sub == selectedMember.reportingManager ? Cred.sub : Cred.reportingManager,
                            clients: selectedClient,
                          },
                        ]);
                        setSelectedDays([]);
                        setSelectedClient([]);
                        setCities([]);
                        setStartAt("");
                        setEndAt("");
                        setClients([]);
                        setSelectedMember({});
                        setOption([]);
                      }
                    } catch (error) {
                      // console.error("An error occurred:", error);
                      setIsModal(false);
                      Swal.fire(
                        "Error",
                        "An error occurred while processing your request",
                        "error"
                      ).finally(() => setIsModal(true));
                    } finally {
                      setButtonLoader({
                        ...buttonLoader,
                        ...{ addSchedule: false },
                      });
                    }
                  }}
                  className="scheduleAdd"
                >
                  {buttonLoader.addSchedule ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-1"
                    />
                  ) : (
                    "Add"
                  )}
                </button>
              </div>

              <br />
              <br />
              <DataTable
                title={"Schedule"}
                columns={column}
                data={allSchedule}
                defaultSortField="title"
                pagination
                selectableRows={false}
                className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
                highlightOnHover={true}
                page
                paginationServer
                progressComponent={
                  <Loading animation={"border"} color={"black"} />
                }
                paginationComponentOptions={{
                  noRowsPerPage: true,
                }}
              />
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  setIsModal(false);
                }}
              >
                Done
              </button>
              <button
                onClick={CreateSchedules}
                type="button"
                className="btn btn-primary"
              >
                {buttonLoader.createSchedules && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-1"
                  />
                )}{" "}
                Create
              </button>
            </Modal.Footer>
          </Modal>
          <div></div>
          {AllSchedules.paginationData.totalPages &&
          AllSchedules.paginationData.totalPages - 1 >
            AllSchedules.paginationData.number ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="primary"
                onClick={onEndReach}
                style={{ width: "200px" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p style={{ fontSize: 18, marginBottom: -2 }}>Load More</p>
                  {loadMore && (
                    <Spinner
                      animation="border"
                      size="sm"
                      style={{ marginLeft: "10px" }}
                    />
                  )}
                </div>
              </Button>
            </div>
          ) : (
            " "
          )}
        </div>
      )}
    </>
  );
}

export default Schedules;
