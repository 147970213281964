import { createSlice } from "@reduxjs/toolkit";

// Initial state setup
const initialBeet = {
    beets: [],
    pageable: {
        totalElements: 0, // Ensure this is initialized correctly
        // Add other pageable properties if needed
    },
};

export const BeetSlice = createSlice({
    name: "Beet",
    initialState: initialBeet,
    reducers: {
        setBeets: (state, action) => {
            return action.payload; // Replace entire state with payload
        },
        addSingleBeet: (state, action) => {
            state.beets = [action.payload, ...state.beets]; // Add new beet
            // Safely increment totalElements
            if (state.pageable && typeof state.pageable.totalElements === 'number') {
                state.pageable.totalElements += 1;
            } else {
                state.pageable.totalElements = 1; // Initialize if undefined
            }
        },
        addOutletToBeet: (state, action) => {
            const { beetId, data } = action.payload;
            return {
              ...state,
              beetData: state.beetData.map((beet) =>
                beet.id === beetId
                  ? { ...beet, outlets: [...(beet.outlets || []), data] }
                  : beet
              ),
            };
          },
          
    },
});

export const {
    addSingleBeet,
    setBeets,
    addOutletToBeet
} = BeetSlice.actions;

export default BeetSlice.reducer;
