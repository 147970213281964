import { useDispatch, useSelector } from "react-redux";
import { permissionIds } from "../constants/constants";
import { getAllMembers } from "../api/member/member-api";
import { deleteAllMembers, setMembers } from "../redux/features/memberSlice";
import Swal from "sweetalert2";
import { useState } from "react";

export function useMemberHook() {
  const Dispatch = useDispatch();
  const Member = useSelector((state) => state.Member);
  const Cred = useSelector((state) => state.Cred);
  const MemberPermission = useSelector(
    (state) => state.Permission.memberPermissions
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);

  async function get() {
    if (
      MemberPermission.some(
        (item) =>
          item == permissionIds.manager || item == permissionIds.viewManager
      )
    ) {
      setIsLoading(true);
      try {
        if (Member.allMembers.length <= 0) {
          Dispatch(deleteAllMembers());
          const MembersArrays = await getAllMembers(0, Cred.token, Cred.sub);
          if (MembersArrays.data.length >= 0) {
            Dispatch(
              setMembers({
                allMembers: MembersArrays.data,
                paginationData: MembersArrays.paginationData,
              })
            );
          }
        }
      } catch (error) {
        Swal.fire({
          title: "Something went wrong!",
          text: "Can't Fetch Employees. Please try After Some Time",
          icon: "error",
        });
        setIsError(error);
      } finally {
        setIsLoading(false);
      }
    }
  }

  function getMembersArrayByArrayIds() {
    try {
      if (Member.allMembers.length > 0) {
        const result = Member.allMembers.map((item) => {
          return {
            employeeId: item.id || null,
            employeeName: `${item.firstName} ${item.lastName}`,
          };
        });
        return result;
      }
      return []
    } catch (error) {
      console.log("Error ::", error);
      Swal.fire({
        title: "Something went wrong!",
        text: "Unable to Access Employees Name",
        icon: "error",
      });
    }
  }

  return { get, isLoading, isError, getMembersArrayByArrayIds };
}
